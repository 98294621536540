import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/shared/components/button';
import { WellFormManager } from 'src/store/well-form-manager/well-form-manager';

import styles from './well-form-geo-tasks-list.module.scss';

type Props = {
  wellFormManager: WellFormManager;
};

export const GeoTasksList = observer(function GeoTasksList({ wellFormManager }: Props) {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.wrapper, wellFormManager.formList.length <= 1 && styles.wrapper_hidden)}>
      <p className={styles.geoTaskText}>Геологические задания</p>
      {wellFormManager.formList.map((wellForm, index) => {
        const isFormActive = wellFormManager.currentFormStore?.id === wellForm.id;

        return (
          <Button
            key={wellForm.id}
            variant={isFormActive ? 'primary' : 'text'}
            onClick={() => wellFormManager.changeForm(wellForm.id)}
          >
            <p>{t('newWellForm:GeaoTasks.geotask', { index: index + 1 })}</p>
          </Button>
        );
      })}
    </div>
  );
});
