import {
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  DragEndEvent,
  DragStartEvent,
  DndContext,
  DragOverlay,
  Active,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import { ReactComponent as DotsIcon } from 'src/shared/assets/icons/dots.svg';
import { Button } from 'src/shared/components/button';
import { FormSelect } from 'src/shared/components/form-components';
import { DateIntervalField } from 'src/shared/entities/control-entities';
import { useStore } from 'src/store';

import { Approach, ApproachStage } from '../../entities/approach-entities';
import { FormStore } from '../../entities/form.entity';
import { NewWellIntervalDateField } from '../../form-controls';
import { DirectoryValuesInStagesPlugin } from '../../plugins/directory-values-in-stages-plugin/directory-values-in-stages-plugin';
import { TOption, TSplitApproachOptions } from '../../types';
import { SectionDropdown } from '../section-dropdown/section-dropdown';
import { SectionRow } from '../section-row/section-row';

import { StageField } from './stage-field';

import styles from './stage-row.module.scss';

type Props = {
  overlay?: boolean;
  stage: ApproachStage;
  approach: Approach;
  formStore: FormStore;
  stageDropdownOptions: TOption[];
  onSplitApproach?({ sectionId, stage, approach }: TSplitApproachOptions): void;
  changeStageType: (stage: ApproachStage, newType: string | number) => void;
  deleteStage: (id: number) => void;
};

export const StageRow = observer(function StageRow({
  overlay,
  stage,
  approach,
  formStore,
  stageDropdownOptions,
  onSplitApproach,
  changeStageType,
  deleteStage,
}: Props) {
  const { t } = useTranslation();
  const { notifications } = useStore();
  const [activeSection, setActiveSection] = useState<Active>();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const { active, attributes, listeners, transform, transition, setNodeRef } = useSortable({ id: stage.id });
  const directoryValuesInStagesPlugin = formStore.plugins.find(
    (plugin): plugin is DirectoryValuesInStagesPlugin => plugin instanceof DirectoryValuesInStagesPlugin
  );

  const overlayItem = stage.sectionsList.sections.find((section) => section.id === activeSection?.id);

  const handleDragStart = ({ active }: DragStartEvent): void => {
    setActiveSection(active);
  };

  const handleChangeType = (newType: string | number | TOption): void => {
    if (typeof newType === 'object') {
      changeStageType(stage, newType.value);
    } else {
      changeStageType(stage, newType);
    }
  };

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (over && active.id !== over.id) {
      const oldIndex = stage.sectionsList.sections.findIndex((item) => item.id === active.id);
      const newIndex = stage.sectionsList.sections.findIndex((item) => item.id === over.id);
      stage.sectionsList.setSectionsAndSwapDates(
        arrayMove(stage.sectionsList.sections, oldIndex, newIndex),
        newIndex,
        oldIndex
      );
    }
  };

  const handleAddNewSection = (sectionType: string | number) => {
    const isSectionDetailingEnabled = !!formStore.fields['sectionsDetailing'].value;

    if (isSectionDetailingEnabled) {
      stage.addNewSection(sectionType);
    } else {
      notifications.showErrorMessageT('newWellForm:Errors.sectionDetailingIsDisabledSection');
    }
  };

  const handleDelete = (): void => {
    deleteStage(stage.id);
  };

  const dateIntervalControl = stage.fieldsList[0];

  useEffect(() => {
    const setValueDateValueDisposer = autorun(() => {
      if (stage.stageType.data['hasSections'] && stage.sectionsList.sections.length > 0) {
        stage.dateField?.setIsVisuallyDisabled({ flagId: stage.id, value: true });
      } else {
        stage.dateField?.setIsVisuallyDisabled({ flagId: stage.id, value: false });
      }
    });

    return () => {
      setValueDateValueDisposer();
    };
  }, [stage]);

  useEffect(() => {
    const disposeStageControl = stage.stageTypeControl.init();

    return () => disposeStageControl();
  }, [stage.stageTypeControl]);

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={{ transform: `translateY(${transform?.y || 0}px)`, transition }}
      className={clsx(
        styles.rowWrapper,
        active?.id === stage.id && styles.rowWrapper__active,
        overlay && styles.rowWrapper__overlay
      )}
    >
      <div className={styles.rowmainContentWrapper}>
        {!stage.isVisuallyDisabled && (
          <>
            <DotsIcon className={styles.icon} {...listeners} />
            <Button
              variant="default"
              className={styles.deleteButton}
              icon={<DeleteIcon width={18} height={18} />}
              onClick={handleDelete}
            />
          </>
        )}

        <div className={styles.inputsWrapper}>
          <FormSelect<string | number | TOption>
            className={styles.stageTypeSelect}
            allowClear={false}
            isDisabled={stage.isVisuallyDisabled}
            value={stage.stageTypeControl.archivedValue ?? stage.stageTypeControl.value}
            options={stage.stageTypeControl.options}
            onChange={handleChangeType}
          />
          {dateIntervalControl instanceof DateIntervalField && (
            <NewWellIntervalDateField
              item={dateIntervalControl}
              durationAllowClear={!directoryValuesInStagesPlugin}
              durationSuffixComponent={
                directoryValuesInStagesPlugin &&
                !dateIntervalControl.isDisabled &&
                !dateIntervalControl.isVisuallyDisabled
                  ? directoryValuesInStagesPlugin.getDurationButton(stage)
                  : undefined
              }
              isDisabled={dateIntervalControl.isDisabled || dateIntervalControl.isVisuallyDisabled}
            />
          )}
          {stage.fieldsList.slice(1).map((field) => {
            return <StageField key={field.fieldId} stage={stage} item={field} formStore={formStore} />;
          })}
          {!!stage.sectionsList.sections.length && (
            <p className={styles.stageDateFieldExplanation}>{t('newWellForm:Stages.stageDateExplanation')}</p>
          )}
        </div>
      </div>
      <div className={styles.sectionsWrapper}>
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis]}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={[...stage.sectionsList.sections]} strategy={verticalListSortingStrategy}>
            {stage.sectionsList.sections.map((section) => (
              <SectionRow
                key={section.id}
                options={stage.sectionsList.sectionOptions}
                section={section}
                stage={stage}
                approach={approach}
                formStore={formStore}
                onSplitApproach={onSplitApproach}
                changeSectionType={stage.sectionsList.changeSectionType}
                deleteSection={stage.sectionsList.deleteSection}
              />
            ))}
          </SortableContext>
          {createPortal(
            <DragOverlay>
              {overlayItem ? (
                <SectionRow
                  overlay
                  section={overlayItem}
                  stage={stage}
                  approach={approach}
                  options={stage.sectionsList.sectionOptions}
                  formStore={formStore}
                  onSplitApproach={onSplitApproach}
                  changeSectionType={stage.sectionsList.changeSectionType}
                  deleteSection={stage.sectionsList.deleteSection}
                />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </div>
      {!!stage.sectionReference && stage.stageType.data.hasSections && (
        <SectionDropdown
          options={stage.sectionsList.sectionOptions}
          isDisabled={stage.isVisuallyDisabled}
          onNewSection={handleAddNewSection}
        />
      )}
    </div>
  );
});
