import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { ComparingDrillingPlanHeader } from 'src/features/comparing-drilling-plan-header';
import { Timeline } from 'src/features/drilling-chart/features/timeline/view/timeline/timeline';
import { IndicatorsViewSettingsSidebar } from 'src/features/drilling-chart/presets/indicators-view-settings-sidebar';
import { DrillingPlanHeader } from 'src/features/drilling-plan-header/drilling-plan-header';
import { HistorySidebar } from 'src/features/history-sidebar';
import { WellForm } from 'src/features/well-form';
import { GeoTasksList } from 'src/features/well-form/well-form-geo-tasks-list/well-form-geo-tasks-list';
import { WellFormSubheader } from 'src/features/well-form/well-form-subheader';
import { useElementSize } from 'src/shared/hooks/use-element-size';
import { useModal } from 'src/shared/hooks/use-modal';
import { useStore } from 'src/store';

import { TimelinePeriodsToggle } from '../../features/drilling-chart/features/timeline-periods-toggle/view';
import { ComparingDrillingRigsChart } from '../../features/drilling-chart/presets/comparing-drilling-rigs-chart';
import { ComparingWellsChart } from '../../features/drilling-chart/presets/comparing-drilling-wells-chart';
import { DrillingRigsChart } from '../../features/drilling-chart/presets/drilling-rigs-chart/drilling-rigs-chart';
import { DrillingWellsChart } from '../../features/drilling-chart/presets/drilling-wells-chart/drilling-wells-chart';
import { IndicatorSettings } from '../../features/drilling-chart/presets/indicators-view-settings-sidebar/entities';
import { RigsViewSettingsSidebar } from '../../features/drilling-chart/presets/rigs-view-settings-sidebar';
import { WellViewSettingsSidebar } from '../../features/drilling-chart/presets/well-view-settings-sidebar';
import { PlanType } from '../../features/drilling-chart/shared/plan-type';

import { DrillingPlanChartStore } from './drilling-plan-chart.store';
import { useFullScreen } from './use-full-screen';

import styles from './drilling-plan-chart.module.scss';

export const DrillingPlanChartPage = observer(function DrillingPlanChartPage() {
  const { ref, width, height } = useElementSize();

  const { portalRoot, isFullScreen, onFullScreen, onFullScreenExit } = useFullScreen();

  const rootStore = useStore();
  const { comparison, editing } = rootStore;
  const isCompareMode = comparison.isComparing;

  const [store] = useState(() => new DrillingPlanChartStore(rootStore));
  const { wellFormManager } = store;

  const {
    timelinePresenter,
    timelinePeriodsTogglePresenter,
    rigsChartStore,
    wellsChartStore,
    horizontalViewport,
    timelineViewport,
    comparingRigsChartStore,
    comparingWellsChartStore,
    indicatorsSettings,
    header,
  } = store;

  useEffect(() => {
    const disposeStore = store.init();

    return (): void => {
      disposeStore?.();
    };
  }, [store]);

  useEffect(() => {
    const disposeTimeline = timelinePresenter.init();

    return (): void => {
      disposeTimeline();
    };
  }, [timelinePresenter, timelinePresenter.init]);

  useEffect(() => {
    wellFormManager.init();
    wellFormManager.onCloseForm = store.onFormClose;
  }, [wellFormManager, store]);

  useEffect(() => {
    if (rigsChartStore) {
      const disposeRigsChart = rigsChartStore.init();

      return () => {
        disposeRigsChart();
      };
    }
  }, [rigsChartStore]);

  useEffect(() => {
    if (comparingRigsChartStore) {
      const disposeRigsChart = comparingRigsChartStore.init();

      return () => {
        disposeRigsChart();
      };
    }
  }, [comparingRigsChartStore]);

  useEffect(() => {
    if (wellsChartStore) {
      const disposeWellsChart = wellsChartStore.init();

      return () => {
        disposeWellsChart();
      };
    }
  }, [wellsChartStore]);

  useEffect(() => {
    if (comparingWellsChartStore) {
      const disposeWellsChart = comparingWellsChartStore.init();

      return () => {
        disposeWellsChart();
      };
    }
  }, [comparingWellsChartStore]);

  const {
    isOpen: isIndicatorsViewSettingsOpen,
    openModal: openIndicatorsViewSettings,
    closeModal: closeIndicatorsViewSettings,
  } = useModal();

  const onIndicatorsViewSettingsApply = (indicatorsSettings: IndicatorSettings[]): void => {
    store.indicatorsSettings.updateIndicators(indicatorsSettings, closeIndicatorsViewSettings);
  };

  const {
    isOpen: isWellsViewSettingsOpen,
    openModal: openWellsViewSettings,
    closeModal: closeWellsViewSettings,
  } = useModal();

  const { isOpen: isHistorySidebarOpened, openModal: openHistorySidebar, closeModal: closeHistorySidebar } = useModal();

  const {
    isOpen: isRigsViewSettingsOpen,
    openModal: openRigsViewSettings,
    closeModal: closeRigsViewSettings,
  } = useModal();

  if (isFullScreen) {
    return createPortal(
      <>
        <main className={clsx(styles.main, wellFormManager.isFormOpen && styles.main_hidden)} ref={ref}>
          {header.displayChartType === PlanType.rigs && !!rigsChartStore && !isCompareMode && (
            <DrillingRigsChart
              store={rigsChartStore}
              containerWidth={width}
              containerHeight={height}
              openIndicatorsViewSettings={openIndicatorsViewSettings}
              dataView={store.dataView}
              headerPresenter={store.headerPresenter}
              indicatorsSettings={indicatorsSettings.settings}
              isEditing={editing.isEditing}
              isFullScreen={isFullScreen}
              onFullScreenExit={onFullScreenExit}
              onWellEdit={wellFormManager.onWellEdit}
              onMakeWellIsPlanned={wellFormManager.makeWellIsPlanned}
              onWellAdd={wellFormManager.onWellAdd}
              onChange={store.reloadChartData}
            />
          )}

          {header.displayChartType === PlanType.rigs && !!comparingRigsChartStore && isCompareMode && (
            <ComparingDrillingRigsChart
              chartStore={comparingRigsChartStore}
              dataView={store.dataView}
              headerPresenter={store.headerPresenter}
              dataHeadersPresenter={comparingRigsChartStore.dataHeadersPresenter}
              dataItemsBackgroundPresenter={comparingRigsChartStore.dataItemsBackgroundPresenter}
              dataItemsCompactPresenter={comparingRigsChartStore.dataItemsCompactPresenter}
              dataItemsFullPresenter={comparingRigsChartStore.dataItemsFullPresenter}
              indicatorsSettings={indicatorsSettings.settings}
              isFullScreen={isFullScreen}
              onFullScreenExit={onFullScreenExit}
            />
          )}

          {header.displayChartType === PlanType.wells && !!wellsChartStore && !isCompareMode && (
            <DrillingWellsChart
              store={wellsChartStore}
              containerWidth={width}
              containerHeight={height}
              openIndicatorsViewSettings={openIndicatorsViewSettings}
              dataView={store.dataView}
              isEditing={editing.isEditing}
              headerPresenter={store.headerPresenter}
              indicatorsSettings={indicatorsSettings.settings}
              isFullScreen={isFullScreen}
              onFullScreenExit={onFullScreenExit}
              onWellAdd={wellFormManager.onWellAdd}
              onWellEdit={wellFormManager.onWellEdit}
            />
          )}

          {header.displayChartType === PlanType.wells && comparingWellsChartStore && isCompareMode && (
            <ComparingWellsChart
              chartStore={comparingWellsChartStore}
              containerWidth={width}
              containerHeight={height}
              horizontalViewport={horizontalViewport}
              headerPresenter={store.headerPresenter}
              indicatorsSettings={indicatorsSettings.settings}
              isFullScreen={isFullScreen}
              onFullScreenExit={onFullScreenExit}
            />
          )}

          <div className={styles.timeline}>
            <TimelinePeriodsToggle
              className={styles.timelinePeriodsToggle}
              selectedPeriod={timelinePeriodsTogglePresenter.selectedPeriod}
              onPeriodChange={timelinePeriodsTogglePresenter.onPeriodToggle}
            />

            <Timeline
              data={timelinePresenter.data}
              viewport={horizontalViewport}
              timelineViewport={timelineViewport}
              onDragDown={timelinePresenter.onDragDown}
              onDragMove={timelinePresenter.onDragMove}
              onRightDragUp={timelinePresenter.onRightDragUp.bind(timelinePresenter)}
              onLeftDragUp={timelinePresenter.onLeftDragUp.bind(timelinePresenter)}
              onDragCancel={timelinePresenter.onRightDragUp.bind(timelinePresenter)}
              onSelectAreaDown={timelinePresenter.onDragDown}
              onSelectAreaMove={timelinePresenter.onSelectAreaMove}
              onSelectAreaUp={timelinePresenter.onSelectAreaUp.bind(timelinePresenter)}
              onSelectAreaCancel={timelinePresenter.onSelectAreaUp.bind(timelinePresenter)}
            />
          </div>
        </main>

        {wellFormManager.isFormOpen && (
          <WellForm
            isFormFetching={wellFormManager.isSaveUpdateLoading}
            isFormLoading={wellFormManager.isFormLoading}
            wellFormManager={wellFormManager}
            onSplitApproach={wellFormManager.splitApproach}
            onAddNewApproach={wellFormManager.addNewApproach}
            additionalTabSubheaderComponent={<GeoTasksList wellFormManager={wellFormManager} />}
            subheader={
              <WellFormSubheader
                title={wellFormManager.currentWellName}
                formStore={wellFormManager.currentFormStore}
                onSave={wellFormManager.saveWell}
                onCancel={wellFormManager.cancelForm}
              />
            }
          />
        )}

        {!!indicatorsSettings.settings && (
          <IndicatorsViewSettingsSidebar
            settings={indicatorsSettings.settings}
            isOpen={isIndicatorsViewSettingsOpen}
            isLoading={indicatorsSettings.isLoading}
            onClose={closeIndicatorsViewSettings}
            onApply={onIndicatorsViewSettingsApply}
          />
        )}

        {!!store.rigsViewSettings && (
          <RigsViewSettingsSidebar
            isOpen={isRigsViewSettingsOpen}
            onClose={closeRigsViewSettings}
            store={store.rigsViewSettings.sidebar}
          />
        )}

        {!!store.wellsViewSettings && (
          <WellViewSettingsSidebar
            isOpen={isWellsViewSettingsOpen}
            onClose={closeWellsViewSettings}
            store={store.wellsViewSettings.sidebar}
          />
        )}
      </>,
      portalRoot
    );
  }

  return (
    <>
      <main className={clsx(styles.main, wellFormManager.isFormOpen && styles.main_hidden)} ref={ref}>
        {header.displayChartType === PlanType.rigs && !!rigsChartStore && !isCompareMode && (
          <DrillingPlanHeader
            store={header}
            onSettingsOpen={openRigsViewSettings}
            onFullScreen={onFullScreen}
            onHistoryShow={openHistorySidebar}
            isChangesDisabled={rigsChartStore.isDataUpdating}
          />
        )}

        {header.displayChartType === PlanType.wells && !!wellsChartStore && !isCompareMode && (
          <DrillingPlanHeader
            store={header}
            onSettingsOpen={openWellsViewSettings}
            onFullScreen={onFullScreen}
            onHistoryShow={openHistorySidebar}
            isDisabled={wellsChartStore.isDataUpdating}
          />
        )}

        {header.displayChartType === PlanType.rigs && isCompareMode && (
          <ComparingDrillingPlanHeader
            displayChartType={header.displayChartType}
            onDisplayChartTypeChange={header.onDisplayChartTypeChange}
            onSettingsOpen={openRigsViewSettings}
            onFullScreen={onFullScreen}
            onComparingModeExit={comparison.completeComparing}
          />
        )}

        {header.displayChartType === PlanType.wells && isCompareMode && (
          <ComparingDrillingPlanHeader
            displayChartType={header.displayChartType}
            onDisplayChartTypeChange={header.onDisplayChartTypeChange}
            onSettingsOpen={openWellsViewSettings}
            onFullScreen={onFullScreen}
            onComparingModeExit={comparison.completeComparing}
          />
        )}

        {header.displayChartType === PlanType.rigs && !!rigsChartStore && !isCompareMode && (
          <DrillingRigsChart
            store={rigsChartStore}
            containerWidth={width}
            containerHeight={height}
            openIndicatorsViewSettings={openIndicatorsViewSettings}
            indicatorsSettings={indicatorsSettings.settings}
            dataView={store.dataView}
            headerPresenter={store.headerPresenter}
            isEditing={editing.isEditing}
            onWellEdit={wellFormManager.onWellEdit}
            onMakeWellIsPlanned={wellFormManager.makeWellIsPlanned}
            onWellAdd={wellFormManager.onWellAdd}
            onChange={store.reloadChartData}
          />
        )}

        {header.displayChartType === PlanType.rigs && !!comparingRigsChartStore && isCompareMode && (
          <ComparingDrillingRigsChart
            chartStore={comparingRigsChartStore}
            dataView={store.dataView}
            headerPresenter={store.headerPresenter}
            dataHeadersPresenter={comparingRigsChartStore.dataHeadersPresenter}
            dataItemsBackgroundPresenter={comparingRigsChartStore.dataItemsBackgroundPresenter}
            dataItemsCompactPresenter={comparingRigsChartStore.dataItemsCompactPresenter}
            dataItemsFullPresenter={comparingRigsChartStore.dataItemsFullPresenter}
            indicatorsSettings={indicatorsSettings.settings}
            isFullScreen={isFullScreen}
            onFullScreenExit={onFullScreenExit}
          />
        )}

        {header.displayChartType === PlanType.wells && !!wellsChartStore && !isCompareMode && (
          <DrillingWellsChart
            store={wellsChartStore}
            containerWidth={width}
            containerHeight={height}
            openIndicatorsViewSettings={openIndicatorsViewSettings}
            dataView={store.dataView}
            isEditing={editing.isEditing}
            headerPresenter={store.headerPresenter}
            indicatorsSettings={indicatorsSettings.settings}
            onWellEdit={wellFormManager.onWellEdit}
            onWellAdd={wellFormManager.onWellAdd}
          />
        )}

        {header.displayChartType === PlanType.wells && !!comparingWellsChartStore && isCompareMode && (
          <ComparingWellsChart
            chartStore={comparingWellsChartStore}
            containerWidth={width}
            containerHeight={height}
            horizontalViewport={horizontalViewport}
            headerPresenter={store.headerPresenter}
            indicatorsSettings={indicatorsSettings.settings}
            isFullScreen={isFullScreen}
            onFullScreenExit={onFullScreenExit}
          />
        )}

        <div className={styles.timeline}>
          <TimelinePeriodsToggle
            className={styles.timelinePeriodsToggle}
            selectedPeriod={timelinePeriodsTogglePresenter.selectedPeriod}
            onPeriodChange={timelinePeriodsTogglePresenter.onPeriodToggle}
          />

          <Timeline
            data={timelinePresenter.data}
            viewport={horizontalViewport}
            timelineViewport={timelineViewport}
            onDragDown={timelinePresenter.onDragDown}
            onDragMove={timelinePresenter.onDragMove}
            onRightDragUp={timelinePresenter.onRightDragUp.bind(timelinePresenter)}
            onLeftDragUp={timelinePresenter.onLeftDragUp.bind(timelinePresenter)}
            onDragCancel={timelinePresenter.onRightDragUp.bind(timelinePresenter)}
            onSelectAreaDown={timelinePresenter.onDragDown}
            onSelectAreaMove={timelinePresenter.onSelectAreaMove}
            onSelectAreaUp={timelinePresenter.onSelectAreaUp.bind(timelinePresenter)}
            onSelectAreaCancel={timelinePresenter.onSelectAreaUp.bind(timelinePresenter)}
          />
        </div>
      </main>

      {wellFormManager.isFormOpen && (
        <WellForm
          wellFormManager={wellFormManager}
          isFormFetching={wellFormManager.isSaveUpdateLoading}
          isFormLoading={wellFormManager.isFormLoading}
          onSplitApproach={wellFormManager.splitApproach}
          onAddNewApproach={wellFormManager.addNewApproach}
          additionalTabSubheaderComponent={<GeoTasksList wellFormManager={wellFormManager} />}
          subheader={
            <WellFormSubheader
              title={wellFormManager.currentWellName}
              formStore={wellFormManager.currentFormStore}
              onSave={wellFormManager.saveWell}
              onCancel={wellFormManager.cancelForm}
            />
          }
        />
      )}

      {!!indicatorsSettings.settings && (
        <IndicatorsViewSettingsSidebar
          settings={indicatorsSettings.settings}
          isOpen={isIndicatorsViewSettingsOpen}
          isLoading={indicatorsSettings.isLoading}
          onClose={closeIndicatorsViewSettings}
          onApply={onIndicatorsViewSettingsApply}
        />
      )}

      {!!store.rigsViewSettings && (
        <RigsViewSettingsSidebar
          isOpen={isRigsViewSettingsOpen}
          onClose={closeRigsViewSettings}
          store={store.rigsViewSettings.sidebar}
        />
      )}

      {!!store.wellsViewSettings && (
        <WellViewSettingsSidebar
          isOpen={isWellsViewSettingsOpen}
          onClose={closeWellsViewSettings}
          store={store.wellsViewSettings.sidebar}
        />
      )}

      <HistorySidebar isOpened={isHistorySidebarOpened} onClose={closeHistorySidebar} />
    </>
  );
});
