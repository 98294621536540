import { observer } from 'mobx-react-lite';

import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import { PlanVersion } from 'src/store/comparison/types';

import styles from './versions-list-item.module.scss';

interface Props {
  version: PlanVersion;
  onVersionChange(planVersion: PlanVersion): void;
  onDeleteVersion?(planVersion: PlanVersion): void;
}

export const VersionsListItem = observer(function VersionsListItem({
  version,
  onVersionChange,
  onDeleteVersion,
}: Props) {
  function onDeleteDraft(event: React.MouseEvent): void {
    event.stopPropagation();
    onDeleteVersion?.(version);
  }

  return (
    <li className={styles.listItem} onClick={() => onVersionChange(version)}>
      {onDeleteVersion && (
        <button className={styles.listItemButton} onClick={onDeleteDraft}>
          <DeleteIcon className={styles.listItemButtonIcon} />
        </button>
      )}

      <p className={styles.listItemText}>{version.data.name}</p>
    </li>
  );
});
