import { action, flow, makeObservable, observable } from 'mobx';

import { parseExcelData, matchExcelHeaders, getJobInfo } from 'src/api/import-file/import-file';
import { ErrorWithTranslation } from 'src/shared/utils/error-with-translation';
import { RootStore } from 'src/store';
import { Directories, isValidationErrorWithCause } from 'src/store/directories/directories.store';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { JobsStore } from 'src/store/jobs/jobs-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { RouterStore } from 'src/store/router/router-store';

import { MatchingValidationError } from './types';

export class MatchingImportStore {
  private readonly i18: I18NextStore;
  private readonly notifications: NotificationsStore;
  private readonly router: RouterStore;
  private readonly fileId: number;
  private readonly sheetName: string;
  private readonly targetPlanVersionId: string;
  private readonly draftStore: DraftsStore;
  private readonly jobsStore: JobsStore;
  private readonly directories: Directories;

  @observable isLoading: boolean = false;
  @observable unmatchedHeaders: HeaderType[] = [];
  @observable data: MatchedItemType[] = [];
  @observable conflicts: TConflict[] = [];
  @observable errorMessage?: string;

  constructor(rootStore: RootStore, fileId: number, sheetName: string, targetPlanVersionId: string) {
    this.fileId = fileId;
    this.sheetName = sheetName;
    this.targetPlanVersionId = targetPlanVersionId;
    this.draftStore = rootStore.drafts;
    this.i18 = rootStore.i18;
    this.router = rootStore.router;
    this.notifications = rootStore.notifications;
    this.directories = rootStore.directories;
    this.jobsStore = rootStore.jobsStore;

    makeObservable(this);
  }

  @action.bound
  effect() {
    this.matchExcelHeaders();
  }

  @action.bound
  addUnmatchedHeader(header: HeaderType): void {
    this.unmatchedHeaders.push(header);
  }

  @action.bound
  removeUnmatchedHeader(value: HeaderType): void {
    this.unmatchedHeaders = this.unmatchedHeaders.filter((item) => item.columnIndex !== value.columnIndex);
  }

  @action.bound
  addDataItem(attribute: string, header: HeaderType): void {
    this.data = this.data.map((item) => {
      if (item.name === attribute) {
        return { ...item, value: header };
      }

      return item;
    });
  }

  @action.bound
  getDataItem(attribute: string): MatchedItemType | null {
    return this.data.find((item) => item.name === attribute) || null;
  }

  @action.bound
  removeDataItem(attrName: string): void {
    this.data = this.data.map((item) => {
      if (item.name === attrName) {
        return { ...item, value: null };
      }

      return item;
    });
  }

  @action.bound
  setData(data: MatchedItemType[]) {
    this.data = data;
  }

  @action.bound
  async clearConflicts() {
    this.conflicts = [];
  }

  @flow.bound
  async *matchExcelHeaders() {
    this.isLoading = true;

    try {
      const match = await matchExcelHeaders(this.fileId, this.sheetName);

      yield;

      this.unmatchedHeaders = match.unmatchedHeaders;
      this.data = [
        ...match.matched,
        ...match.unmatchedAttributes.map((attribute) => {
          return {
            name: attribute,
            value: null,
          };
        }),
      ];
    } catch (error) {
      yield;

      if (error instanceof ErrorWithTranslation) {
        this.notifications.showErrorMessage(error.translateError(this.i18.t));
        return;
      }
      this.notifications.showErrorMessageT('drillingCarpet:UploadPlanModal.uploadError');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *parseExcelData() {
    this.isLoading = true;

    try {
      const jobId = await parseExcelData(this.fileId, this.sheetName, this.data, this.targetPlanVersionId);
      yield;

      const jobData = await getJobInfo(jobId);
      yield;

      this.jobsStore.registerJob(jobData);

      this.router.push('/carpet/wells');

      // await this.draftStore.fetchDraftsList();

      // const newImportDraft = this.draftStore.draftsList.find((draft) => draft.id === res.planVersionId);

      // if (newImportDraft) {
      //   this.draftStore.setDraft(newImportDraft.id, newImportDraft.data.parentVersionId);
      //   this.router.push(`/carpet/conflicts?planVersionId=${res.planVersionId}`);
      // } else {
      //   throw new Error('import draft is not presented');
      // }
    } catch (error) {
      yield;
      console.error(error);
      if (error instanceof ErrorWithTranslation) {
        this.notifications.showErrorMessage(error.translateError(this.i18.t));
        return;
      }

      if (isValidationErrorWithCause<MatchingValidationError>(error)) {
        for (const field of error.reason) {
          const label = this.directories.getFieldLabel(field.fieldId);
          const errorString = `${field.message}: ${label || field.fieldId}`;
          this.notifications.showErrorMessage(errorString);
        }

        return;
      }
      this.notifications.showErrorMessageT('matching:saveError');
    } finally {
      this.isLoading = false;
    }
  }
}

export const validateData = (data: TData) => {
  for (let prop in data) {
    if (data[prop] === null) {
      const error = new ErrorWithTranslation();
      error.errorTranslation = 'matching:notAllDataIsFilledInError';
      throw error;
    }
  }
};

export type HeaderType = {
  columnIndex: number;
  title: string;
};

export type TData = Record<string, HeaderType | null>;

export type TConflict = {
  name: string;
  value: string | number;
  options: (string | number)[];
};

export const isValidConflicts = (res: any): res is TConflict[] => {
  if (Array.isArray(res)) return true;
  return false;
};

export type MatchedItemType = {
  name: string;
  value: HeaderType | null;
};

export type MatchHeadersType = {
  fileId: number;
  unmatchedAttributes: string[];
  unmatchedHeaders: HeaderType[];
  matched: MatchedItemType[];
};

export type TTripleData = {
  id: number;
  hasErrors: boolean;
};

export type TTriplesList = {
  errors: string | number;
  rows: string | number;
  planVersionId: number;
  triples: TTripleData[];
};

export type TTriple = {
  id: number;
  status: string;
  objectType: string;
  createdAt: number;
  data: {
    $validation?: {
      fieldId: string;
      message: string;
      rawValue: string | number | boolean;
      dataValue: null | string | number | boolean;
    }[];
    planVersionId: number;
    rigOperationId: number;
    wellPlacementId: number;
    geologicalTaskId: number;
  };
};
