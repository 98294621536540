import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { VersionsListItem } from 'src/features/plan-version-sidebar/versions-list-item';
import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { USER_ADMIN_ROLE } from 'src/shared/constants/user';
import { useStore } from 'src/store';
import { PlanVersion, TArchivedPlansGroup } from 'src/store/comparison/types';

import styles from './archived-versions-group.module.scss';

type Props = {
  versionsGroup: TArchivedPlansGroup;
  onDeletePlanVersion(planVersion: PlanVersion): void;
  onVersionChange(planVersion: PlanVersion): void;
};

export const ArchivedVersionsGroup = observer(function ArchivedVersionsGroup({
  versionsGroup,
  onDeletePlanVersion,
  onVersionChange,
}: Props) {
  const { t } = useTranslation();
  const { notifications, auth } = useStore();

  const canUserDeleteArchivedVersions = !!auth.userInfo?.realm_access.roles?.includes(USER_ADMIN_ROLE);

  if (!versionsGroup.months.length) {
    return null;
  }

  const handleDeleteVersion = (version: PlanVersion) => {
    if (version.data.availableForDashboardLite) {
      notifications.showErrorMessage(t('drawers:planVersion.deleteAnalyticsVersionError'));
    } else {
      onDeletePlanVersion(version);
    }
  };

  return (
    <Collapse key={versionsGroup.year} defaultActiveKey={versionsGroup.year}>
      <Panel key={versionsGroup.year} header={versionsGroup.year} className={styles.text}>
        {versionsGroup.months.map((monthGroup) => (
          <Collapse key={monthGroup.month} defaultActiveKey={monthGroup.month}>
            <Panel key={monthGroup.month} header={t(monthGroup.month)} className={styles.text}>
              <ul>
                {monthGroup.plans.map((plan) => (
                  <VersionsListItem
                    key={plan.id}
                    version={plan}
                    onDeleteVersion={canUserDeleteArchivedVersions ? handleDeleteVersion : undefined}
                    onVersionChange={onVersionChange}
                  />
                ))}
              </ul>
            </Panel>
          </Collapse>
        ))}
      </Panel>
    </Collapse>
  );
});
