import { action, makeObservable, reaction } from 'mobx';

import { fetchComputedValues } from 'src/api/new-well/requests';
import { serializeFormForRequest } from 'src/api/new-well/serializers/serialize-form-for-request';
import { BaseApiError } from 'src/errors';
import { Item } from 'src/shared/entities/abstract-control-entities';
import { DateIntervalField } from 'src/shared/entities/control-entities';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { RequestsQueueController } from 'src/shared/utils/requests-queue-controller';
import { RootStore } from 'src/store';

import { FormStore } from '../entities/form.entity';
import { TSerializedFormValues } from '../entities/types';

import { FormPluginWithFieldsProcessing } from './abstract-form-plugin.entity';

const CALCULATION_ERROR_LIMIT = 10000;

export class ComputeValuesPlugin extends FormPluginWithFieldsProcessing {
  private requestController = new RequestsQueueController();
  private _prevFormState: string | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }

  connect(form: FormStore): VoidFunction {
    this.form = form;
    this.setInitialFormState();

    const processItem = (item: Item) => {
      const disposer = reaction(
        () => {
          // необходима обработка конкретно этого контрола, т.к. он "специфический"
          if (item instanceof DateIntervalField) {
            return { value: item.duration, computeTags: item.computeTags };
          }

          return {
            value: item.value,
            computeTags: item.computeTags,
          };
        },
        ({ value, computeTags }, { value: prevValue }) => {
          const isCurrentValueEqualToPrevValue = ((): boolean => {
            // If the difference in values ​​does not exceed CALCULATION_ERROR_LIMIT, then the values ​​are considered equal, and the difference is considered to be the calculation error
            if (!Number.isNaN(Number(value) && !Number.isNaN(Number(prevValue)))) {
              const diff = Number(value) - Number(prevValue);

              if (Math.abs(diff) * CALCULATION_ERROR_LIMIT < 1) {
                return true;
              }

              return false;
            }

            return value === prevValue;
          })();

          if (computeTags && !isCurrentValueEqualToPrevValue) {
            this.getComputedValuesAndUpdateForm(computeTags);
          }
        }
      );

      return disposer;
    };

    const disposer = this.processItems(processItem);

    return () => {
      disposer?.();
      this.requestController.killController();
    };
  }

  @action.bound
  private setInitialFormState() {
    if (!this.form) {
      return;
    }

    const serializedForm = serializeFormForRequest(this.form.tabs);
    this.setPrevFormState(serializedForm);
  }

  private get prevFormState(): string | null {
    return this._prevFormState;
  }

  private setPrevFormState(form: TSerializedFormValues | string) {
    if (typeof form === 'string') {
      this._prevFormState = form;
    } else {
      this._prevFormState = JSON.stringify(form);
    }
  }

  private async getComputedValuesAndUpdateForm(computeTags: string[]): Promise<void> {
    const form = this.form;
    if (!form) {
      return;
    }

    const pendingEventId = getRandomNumber();
    form.addPendingEvent(pendingEventId);

    try {
      await this.requestController.makeRequest(async () => {
        const response = await fetchComputedValues(form.tabs, this.prevFormState, computeTags);
        this.setPrevFormState(response);
        form.setFormValues(response);
      });
    } catch (e) {
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.rootStore.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.rootStore.notifications.showErrorMessageT('errors:NewWellForm.failedToComputeValues');
    } finally {
      form.removePendingEvent(pendingEventId);
    }
  }
}
